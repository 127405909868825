import * as React from "react";
import arrow from "../../assets/svg/arrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import checked from "../../assets/svg/checked.svg";
import checked1 from "../../assets/svg/checked1.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneIcon from "@mui/icons-material/Done";
import "./index.scss";

interface FormProps {
  settings: any;
}

const Form: React.FC<FormProps> = ({ settings }) => {
  const [service, setService] = React.useState("");
  const [sent, setSent] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [form, setForm] = React.useState({
    businessName: "",
    businessWebsite: "",
    businessEmail: "",
    businessAddress: "",
    businessSocialProfile: "",
  });

  const handleForm = (event: any, name: any) => {
    setForm({ ...form, [name]: event.target.value });
  };

  const handleSubmit = async (e: any) => {
    setLoad(true);
    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessName: form.businessName || "N/A",
          businessWebsite: form.businessWebsite || "N/A",
          businessEmail: form.businessEmail || "N/A",
          businessAddress: form.businessAddress || "N/A",
          businessSocialProfile: form.businessSocialProfile || "N/A",
        }),
      });
      if (response.ok) {
        setLoad(false);
        setSent(true);
        setForm({
          businessName: "",
          businessWebsite: "",
          businessEmail: "",
          businessAddress: "",
          businessSocialProfile: "",
        });
        setService("");
        window?.dataLayer.push({
          event: "form_submit",
          event_name: "form_submit",
          email: form.businessEmail,
          quote: "Get Listed",
          submit: "Succes",
        });
        window?.dataLayer.push({
          event: "conversion_event_submit_lead_form",
          event_name: "conversion_event_submit_lead_form",
        });
      } else {
        console.log("Failed to send email.");
        window?.dataLayer.push({
          event: "form_submit",
          event_name: "form_submit",
          email: form.businessEmail || "No Email",
          quote: "Get Listed",
          submit: "Faild",
        });
      }
    } catch (error) {
      console.log("Error occurred while sending the email.");
    }
  };

  return (
    <section className="form">
      <div className="container">
        {!sent && (
          <>
            <h2>{settings?.titleForm}</h2>
            <p className="form-desc-p">{settings?.contentForm}</p>
            <h3>{settings?.section1}</h3>
            <div className="form-section">
              <div className="form-line full-form-line">
                <label>
                  {settings?.field1} <span>*</span>
                </label>
                <input
                  type="text"
                  value={form?.businessName}
                  onChange={(e) => handleForm(e, "businessName")}
                  placeholder={settings?.placeholder1}
                />
                <BusinessIcon />
              </div>
              <div className="form-line full-form-line">
                <label>
                  {settings?.field2} <span>*</span>
                </label>
                <input
                  type="email"
                  value={form?.businessEmail}
                  onChange={(e) => handleForm(e, "businessEmail")}
                  placeholder={settings?.placeholder2}
                />
                <AlternateEmailIcon />
              </div>
              <div className="form-line full-form-line">
                <label>
                  {settings?.field3} <span>*</span>
                </label>
                <input
                  type="text"
                  value={form?.businessAddress}
                  onChange={(e) => handleForm(e, "businessAddress")}
                  placeholder={settings?.placeholder3}
                />
                <LocationOnIcon />
              </div>
              <div className="form-line full-form-line">
                <label>{settings?.field4}</label>
                <input
                  type="text"
                  value={form?.businessWebsite}
                  onChange={(e) => handleForm(e, "businessWebsite")}
                  placeholder={settings?.placeholder4}
                />
                <LanguageIcon />
              </div>
              {/* <div className="form-line full-form-line">
            <label>{settings?.field5}</label>
            <input
              type="text"
              value={form?.businessSocialProfile}
              onChange={(e) => handleForm(e, "businessSocialProfile")}
            />
          </div> */}
            </div>
          </>
        )}
        {/* <h3>{settings?.section2}</h3>
        <div className="form-section">
          <div className="form-line full-form-line">
            <label>
              {settings?.field5} <span>*</span>
            </label>
            <input
              type="text"
              value={form?.firstName}
              onChange={(e) => handleForm(e, "firstName")}
            />
          </div>
          <div className="form-line full-form-line">
            <label>
              {settings?.field6} <span>*</span>
            </label>
            <input
              type="text"
              value={form?.lastName}
              onChange={(e) => handleForm(e, "lastName")}
            />
          </div>
          <div className="form-line full-form-line">
            <label>
              {settings?.field7} <span>*</span>
            </label>
            <input
              type="email"
              value={form?.email}
              onChange={(e) => handleForm(e, "email")}
            />
          </div>
          <div className="form-line full-form-line">
            <label>
              {settings?.field8} <span>*</span>
            </label>
            <input
              type="text"
              value={form?.phoneNumber}
              onChange={(e) => handleForm(e, "phoneNumber")}
            />
          </div>
        </div> */}
        {/* <h3>{settings?.section3}</h3> */}
        {/* <div className="form-section">
          <label>{settings?.field9}</label>
          <br />
          <br />
          <div className="answers-options">
            {settings?.servicesList?.map((e: any, i: any) => {
              return (
                <div
                  key={i}
                  className={`${
                    service === e?.name
                      ? "answers-options-item active"
                      : "answers-options-item"
                  }`}
                  onClick={() => setService(e?.name)}
                >
                  <img
                    src={service === e?.name ? checked1 : checked}
                    alt={e?.name}
                  />
                  {e?.name}
                </div>
              );
            })}
          </div>
          <div className="form-line full-form-line-all">
            <label>{settings?.field10}</label>
            <input
              type="text"
              value={form?.howMany}
              onChange={(e) => handleForm(e, "howMany")}
            />
          </div>
        </div> */}
        <div className="button-options">
          {!sent && (
            <>
              {load && <CircularProgress />}
              {!load && (
                <div className="button-options-item" onClick={handleSubmit}>
                  {settings?.button}
                </div>
              )}
            </>
          )}
          {sent && (
            <div className="form-desc">
              <CheckCircleIcon />
              <h4>{settings?.message}</h4>
              <p>{settings?.message2}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Form;
